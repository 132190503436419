import { useQuery } from '@tanstack/react-query';
import { fetchRefundRequestStatus } from '../../views/features/refundRequest/api';
import { getStatusLabel, REFUND_REQUEST_STATUS } from '../../common/utilities/constants';

export const useRefundRequestStatuses = (params) => {
  return useQuery({
    queryKey: ['refund-request-statuses', params],
    queryFn: async () => {
      const statuses = (await fetchRefundRequestStatus(params)) || {};

      const newStatus = Object.entries(statuses)
        .map(([key, value]) => {
          if (key !== 'all' && !REFUND_REQUEST_STATUS.some((item) => item.value === key)) {
            return null;
          }

          return {
            value: key,
            label: getStatusLabel(key, REFUND_REQUEST_STATUS) + ` (${value})`
          };
        })
        .filter((status) => status !== null);

      return newStatus;
    },
    enabled: !!params,
    staleTime: 1000 * 20, // 20 seconds
    cacheTime: 1000 * 20, // 20 seconds
    refetchOnWindowFocus: true
  });
};
