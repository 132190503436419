import { apiClientV2 } from '../../services/axios';
export * from './refund';
export * from './transfer';
export * from './bank';
export * from './business';

export const uploadFiles = async ({ feature_type, files = [] }, onUploadProgress) => {
  try {
    const form = new FormData();
    form.append('feature_type', feature_type);
    files.forEach((file) => form.append('files', file));

    const response = await apiClientV2.post('/upload', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    });

    if (response.status == 201) {
      return { status: response.status, data: response.data };
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const fetchCurrency = async () => {
  try {
    const res = await apiClientV2.get('/currency');
    return res.status === 200 ? res.data : { resources: [] };
  } catch (e) {
    console.error(e);
    return { resources: [] };
  }
};
