import { Button, Checkbox, Form, Input, Col, message, Row, Select, Space, Spin, Typography } from 'antd';
import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import apiClient from '../../../services/axios';
import { MOBILE_TYPE_OPTIONS, USER_ROLE } from '../../../common/utilities/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPhoneNumber } from '../../../utility/utils';
import UploadFileCustom from '../../../common/components/UploadFile';
import { ButtonWithTooltip } from '../../features/common/components/ButtonWithTooltip';
import BankAsyncSelect from '../../features/common/components/BankAsyncSelect';
const { Title, Text } = Typography;

const ProfilePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const action = searchParams.get('action') || 'view';
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [businessInfoVerified, setBusinessInfoVerified] = useState(false);
  const [acieSeg, setAcieSeg] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [form] = Form.useForm();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  // const [aciAccountTooltipOpened, setAciAccountTooltipOpened] = useState(false);
  const aciAccountRef = useRef(null);

  // useEffect(() => {
  //   dispatch(fetchUserInfoThunk());
  // }, []);

  useEffect(() => {
    const areaCode =
      MOBILE_TYPE_OPTIONS.filter((opt) => opt.value === userInfo?.country)?.[0] || MOBILE_TYPE_OPTIONS[0];
    form.setFieldsValue({
      ...userInfo,
      bank_account: userInfo?.bank_account || {},
      business: userInfo?.business || {},
      areaCode: areaCode?.value,
      role: userInfo?.role == USER_ROLE.FreeTCustomer ? '일반 고객' : '사업자',
      phone: userInfo?.phone ? userInfo?.phone.replace(areaCode?.code, '') : ''
    });
    setAcieSeg(userInfo.acie_id && userInfo.acie_password);
  }, [userInfo]);

  const handleFinish = async (values) => {
    const role = userInfo?.role;
    if (role === USER_ROLE.FreeTBusiness && !businessInfoVerified) {
      const verified = await verifyBusiness(values.business?.registration_number);
      if (!verified) return;
    }
    const body = {
      ...values,
      phone: values?.phone ? getPhoneNumber(values?.areaCode, values?.phone) : null,
      country: values?.areaCode
    };
    delete body.areaCode;
    delete body.role;
    if (
      Object.keys(body?.bank_account || {}).length === 0 ||
      Object.values(body?.bank_account || {}).every((value) => !value || value === '')
    ) {
      delete body.bank_account;
    }
    if (
      Object.keys(body?.business || {}).length === 0 ||
      Object.values(body?.business || {}).every((value) => !value || value === '')
    ) {
      delete body.business;
    }
    await updateProfile(body);
  };

  const updateProfile = async (body) => {
    setLoadingUpdate(true);
    try {
      await apiClient.put(`/user/${userInfo?.id}`, body);
      dispatch(fetchUserInfoThunk());
      setLoadingUpdate(false);
      message.success('Update Profile successfully');
    } catch (error) {
      setLoadingUpdate(false);
      message.error(error.response.data?.message || 'Update Profile Failed');
    }
  };

  const isValidForm = (values) => {
    const role = userInfo?.role;
    const isValidProfile = values.full_name && values.username;
    let isValidBankAccount = true;
    const fillBankAccount =
      values.bank_account?.name ||
      values.bank_account?.bank_id ||
      values.bank_account?.holder_name ||
      values.bank_account?.account_number;
    if (fillBankAccount) {
      isValidBankAccount =
        values.bank_account?.name &&
        values.bank_account?.bank_id &&
        values.bank_account?.holder_name &&
        values.bank_account?.account_number;
    }
    if (role === USER_ROLE.FreeTCustomer) {
      return isValidProfile && isValidBankAccount;
    }

    const isValidBusinessInfo =
      values.business?.registration_number &&
      values.business?.name &&
      values.business?.representative_name &&
      values.business?.email &&
      // values.business?.business_url &&
      values.business?.pictures?.length > 0;
    const isValidACIE = acieSeg ? values.acie_id && values.acie_password : true;
    return isValidBusinessInfo && isValidProfile && isValidACIE && isValidBankAccount;
  };

  const verifyBusiness = async (registration_number) => {
    try {
      setVerifyLoading(true);
      const { data } = await apiClient.post('/business/verify', { registration_number });

      if (data.valid) {
        message.success('유효한 등록번호');
        setBusinessInfoVerified(true);
        return true;
      }

      message.error('잘못된 등록번호');
      setBusinessInfoVerified(false);
      return false;
    } catch (e) {
      message.error('잘못된 등록번호');
      setBusinessInfoVerified(false);
      return false;
    } finally {
      setVerifyLoading(false);
    }
  };

  const WithAciAccountButton = () => (
    <ButtonWithTooltip
      // tooltipOpen={acieSeg && aciAccountTooltipOpened}
      tooltipOpen={false}
      buttonTitle="있어요"
      tooltipContent={
        <>
          사용하시는 ACI 계정 및 비밀번호를 입력해 주세요
          <br />
          *물류 연동 용도 외에 사용하지 않습니다.
        </>
      }
      buttonProps={{
        type: acieSeg ? 'primary' : 'default',
        onClick: () => {
          setAcieSeg(true);
          // setAciAccountTooltipOpened(true);
        }
      }}
    />
  );

  const WithoutAciAccountButton = () => (
    <ButtonWithTooltip
      // tooltipOpen={!acieSeg && aciAccountTooltipOpened}
      tooltipOpen={false}
      buttonTitle="없어요"
      tooltipContent={
        <>
          계정이 없는 경우 프리티고에서 ACI와 계약 후 아이디를 공유드립니다.
          <br />
          물류비용은 개인 회원 대비 약 3~40% 할인된 요율로 제공되며 회원가입 후 메일로 배송 요율이 안내됩니다.
        </>
      }
      buttonProps={{
        type: acieSeg ? 'default' : 'primary',
        onClick: () => {
          setAcieSeg(false);
          // setAciAccountTooltipOpened(true);
        }
      }}
    />
  );

  const onEditMode = () => {
    if (action === 'view') {
      navigate(`/profile?action=edit`, { replace: true });
    }
  };

  return (
    <>
      <HeaderFT2
        title={'회원 정보'}
        rightIems={
          action === 'view' ? (
            <Button size="large" style={{ width: '140px' }} onClick={onEditMode}>
              수정
            </Button>
          ) : (
            <></>
          )
        }
      />
      {/* <Content> */}
      <Form
        style={{ backgroundColor: '#F2F4F7', marginBottom: action === 'edit' ? '130px' : '50px' }}
        form={form}
        disabled={action === 'view'}
        name="profile"
        layout="vertical"
        onFinish={handleFinish}>
        <Spin tip="Loading" spinning={loadingUpdate}>
          <Space direction="vertical" style={{ backgroundColor: '#FFF', width: '100%', padding: '20px 15px' }}>
            <Form.Item name="full_name" label={<b className="gray-700">이름</b>}>
              <Input size="large" />
            </Form.Item>
            <Form.Item name="username" label={<b className="gray-700">이메일</b>}>
              <Input size="large" disabled />
            </Form.Item>
            <Form.Item name="role" label={<b className="gray-700">유형</b>}>
              <Input size="large" disabled />
            </Form.Item>
            <label className="w-100">
              <span className="d-flex justify-content-between w-100">
                <b className="gray-700">전화 번호</b>
              </span>
            </label>
            {action !== 'view' && (
              <Form.Item name="areaCode">
                <Select disabled={action === 'view'} size="large">
                  {MOBILE_TYPE_OPTIONS.map((opt, idx) => (
                    <Select.Option key={idx} value={opt.value} label={opt.label}>
                      <Space>
                        {/* {opt.icon} */}
                        {opt.label}
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Space.Compact style={{ width: '100%' }} size="large">
              <Form.Item name="phone" className="w-100">
                <Input size="large" disabled={action === 'view'} placeholder="전화 번호" className="w-100" />
              </Form.Item>
            </Space.Compact>
          </Space>
          <Space
            direction="vertical"
            style={{ backgroundColor: '#FFF', width: '100%', padding: '20px 15px', marginTop: '15px' }}>
            {/* Title */}
            <Title level={5} style={{ marginBottom: '24px' }}>
              은행 정보 입력 (환급용)
            </Title>
            {/* Name */}
            <Form.Item name={['bank_account', 'name']} label={<div className="text-14 gray-900 fw-5">이름</div>}>
              <Input placeholder="이름 입력" size="large" />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                {/* Bank Select */}
                <Form.Item name={['bank_account', 'bank_id']} label={<div className="text-14 gray-900 fw-5">은행</div>}>
                  <BankAsyncSelect label="은행" placeholder="은행" style={{ height: '40px', marginTop: '3px' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* Holder name */}
                <Form.Item
                  name={['bank_account', 'holder_name']}
                  label={<div className="text-14 gray-900 fw-5">예금주</div>}>
                  <Input placeholder="예금주" size="large" />
                </Form.Item>
              </Col>
            </Row>
            {/* Account Number */}
            <Form.Item
              name={['bank_account', 'account_number']}
              label={<div className="text-14 gray-900 fw-5">계좌 번호</div>}>
              <Input placeholder="계좌 번호" size="large" />
            </Form.Item>
          </Space>
          {userInfo?.role == USER_ROLE.FreeTBusiness && (
            <>
              <Space
                direction="vertical"
                style={{ backgroundColor: '#FFF', width: '100%', padding: '20px 15px', marginTop: '15px' }}>
                <Row justify="space-between">
                  <Title level={5}>사업자 정보</Title>
                </Row>
                <Spin spinning={verifyLoading}>
                  <Form.Item
                    label={<div className="text-14 gray-900 fw-5">사업자 등록 번호</div>}
                    style={{ marginBottom: 0 }}>
                    <Form.Item
                      name={['business', 'registration_number']}
                      validateTrigger="onBlur"
                      style={{ display: 'inline-block', width: action !== 'edit' ? '100%' : 'calc(100% - 88px)' }}>
                      <Input
                        placeholder="숫자만 입력해 주세요"
                        size="large"
                        onChange={() => setBusinessInfoVerified(false)}
                      />
                    </Form.Item>
                    {action === 'edit' && (
                      <Button
                        type="primary"
                        size="large"
                        block
                        style={{
                          margin: '0 0 8px 8px',
                          display: 'inline-block',
                          width: '80px',
                          fontSize: '14px'
                        }}
                        onClick={() => verifyBusiness(form.getFieldValue(['business', 'registration_number']))}>
                        인증하기
                      </Button>
                    )}
                  </Form.Item>
                </Spin>
                {/* name */}
                <Form.Item name={['business', 'name']} label={<div className="text-14 gray-900 fw-5">상호명</div>}>
                  <Input placeholder="상호명" size="large" />
                </Form.Item>
                {/* representative_name */}
                <Form.Item
                  name={['business', 'representative_name']}
                  label={<div className="text-14 gray-900 fw-5">대표자 이름</div>}>
                  <Input placeholder="대표자 이름" size="large" />
                </Form.Item>
                {/* pictures */}
                <Form.Item
                  label={<div className="text-14 gray-900 fw-5">사업자 등록증</div>}
                  name={['business', 'pictures']}>
                  <UploadFileCustom
                    maxCount={5}
                    isViewOnly={action === 'view'}
                    accept=".png,.jpg,.jpeg"
                    description="이미지 업로드"
                    upload_hint="JPG or PNG..."
                    feature_type="business_account"
                  />
                </Form.Item>
                {/* email */}
                <Form.Item
                  name={['business', 'email']}
                  label={<div className="text-14 gray-900 fw-5">기업 이메일</div>}
                  rules={[
                    {
                      type: 'email',
                      message: '이메일 형식이 올바르지 않아요'
                    }
                  ]}>
                  <Input placeholder="연락용 이메일을 작성해 주세요" size="large" />
                </Form.Item>
                {/* postal_code */}
                <Form.Item name={['business', 'postal_code']} label="우편 번호">
                  <Input placeholder="우편 번호" size="large" />
                </Form.Item>
                {/* address */}
                <Form.Item
                  name={['business', 'address']}
                  label={
                    <>
                      <div>
                        사업장 주소 <br />{' '}
                        {action === 'edit' && (
                          <span style={{ color: '#F63D68' }}>* 사업장 주소는 영문으로 입력해 주세요</span>
                        )}
                      </div>
                    </>
                  }>
                  <Input placeholder="사업장 주소" size="large" />
                </Form.Item>
                {/* product_type */}
                <Form.Item name={['business', 'product_type']} label="취급 제품 종류 (예 : 의류, 주방용품 등)">
                  <Input placeholder="의류, 주방용품, 명품 등" size="large" />
                </Form.Item>
              </Space>
              <Space
                direction="vertical"
                style={{ backgroundColor: '#FFF', width: '100%', padding: '20px 15px', marginTop: '15px' }}>
                <Title level={5} style={{ display: 'flex', gap: '8px' }}>
                  <Checkbox size="large" checked readonly>
                    <div className="text-14 gray-900 fw-5">ACI 계정</div>
                  </Checkbox>
                </Title>
                <Row gutter={8} className="my-3" ref={aciAccountRef}>
                  <Col span={12}>
                    <WithAciAccountButton />
                  </Col>
                  <Col span={12}>
                    <WithoutAciAccountButton />
                  </Col>
                </Row>
                <strong style={{ color: 'red' }}>
                  사용하시는 ACI 계정 및 비밀번호를 입력해 주세요 <br />
                  *물류 연동 용도 외에 사용하지 않습니다
                </strong>
                {acieSeg ? (
                  // Have ACIE
                  <>
                    <Form.Item className="mt-2" name="acie_id" style={{ marginBottom: '8px' }} label="ACI ID">
                      <Input placeholder="ACI" size="large" />
                    </Form.Item>
                    <Form.Item name="acie_password" style={{ marginBottom: '8px' }} label="ACI 비밀번호">
                      <Input.Password placeholder="비밀번호" size="large" />
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
                <Checkbox size="large" checked readonly>
                  <div className="text-14 gray-900 fw-5">
                    ACI 계정 제공 동의 <span style={{ color: 'red' }}>*</span>
                  </div>
                </Checkbox>
              </Space>
            </>
          )}
          {action === 'edit' && (
            <div className="fixed-bottom-container">
              <Form.Item shouldUpdate style={{ margin: '0' }}>
                {({ getFieldsValue }) => (
                  <Button
                    htmlType="submit"
                    loading={loadingUpdate}
                    block
                    type="primary"
                    disabled={!isValidForm(getFieldsValue())}>
                    저장
                  </Button>
                )}
              </Form.Item>
            </div>
          )}
        </Spin>
      </Form>
      {/* </Content> */}
    </>
  );
};

export default ProfilePage;
