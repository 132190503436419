import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filter, flatMap } from 'lodash';
import { getStatusLabel, REFUND_REQUEST_STATUS } from '../../common/utilities/constants';
import { fetchDefaultRecipient } from '../../views/features/refundRequest/api';

export const fetchDefaultRecipientThunk = createAsyncThunk('ft/refundRequest/defaultRecipient/get', async (params) => {
  return await fetchDefaultRecipient(params);
});

const initialState = {
  loading: false,
  isOpenSearchDrawer: false,
  totalPage: 0,
  totalResources: 0,
  page: 1,
  size: 20,
  refund_rate: parseFloat(process.env.REACT_APP_REFUND_RATE) || 0.083,
  resources: [],
  statusOptions: [],
  pending_count: 0,
  default_recipient: null,
  filters: {}
};

export const refundRequestSlice = createSlice({
  name: 'refundRequest',
  initialState,
  reducers: {
    openSearchDrawer: (state) => {
      state.isOpenSearchDrawer = true;
    },
    closeSearchDrawer: (state) => {
      state.isOpenSearchDrawer = false;
    },
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeFilters: (state, action) => {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefaultRecipientThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDefaultRecipientThunk.fulfilled, (state, action) => {
        state.default_recipient = action.payload;
        state.loading = false;
      })
      .addCase(fetchDefaultRecipientThunk.rejected, (state, action) => {
        state.loading = false;
        state.default_recipient = null;
      });
  }
});

export const { openSearchDrawer, closeSearchDrawer, changePage, changeSize, changeFilters } =
  refundRequestSlice.actions;

export default refundRequestSlice.reducer;
