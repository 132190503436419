import { Button, Card, Image, Modal, Progress, Upload } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { UploadIcon } from '../../../assets/icons/Icons';
import { uploadFiles } from '../../api';
import './style.scss';
import PDFViewer from '../PdfViewer';
import PdfThumbnail from '../PdfThumbnail';

// const PDFViewer = dynamic(() => import('../../../common/components/PdfViewer'), {
//   ssr: false,
// });

const UploadFileCustom = ({
  multiple = true,
  accept,
  maxCount = 5,
  value = [],
  onChange,
  isViewOnly = false,
  feature_type = 'refund_request',
  upload_hint = 'PDF or JPG',
  description = '',
  ...remain
}) => {
  description = description || `이미지 업로드 (최대 ${maxCount} 개)`;
  const [fileList, setFileList] = useState([]);
  const [openPDFModals, setOpenPDFModals] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [percentUpload, setPercentUpload] = useState(0);
  const setPDFView = (key, isLoading) => {
    setOpenPDFModals((state) => {
      const newState = [...state];
      newState[key] = isLoading;
      return newState;
    });
  };
  const uploadImage = async (files) => {
    let arrayFile = [...files];
    const numCurFile = value?.length || 0;
    if (files?.length > maxCount - numCurFile) {
      arrayFile = [...files].slice(0, maxCount - numCurFile);
    }
    const payload = {
      feature_type,
      files: arrayFile
    };
    setUploading(true);
    const res = await uploadFiles(payload, (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercentUpload(percent);
      if (percent === 100) {
        setTimeout(() => {
          setUploading(false);
          setPercentUpload(0);
        }, 300);
      }
    });
    if (res?.data) {
      onChange([...value, ...res.data]);
      value = [...value, ...res.data];
    }
  };

  useEffect(() => {
    if (fileList?.length > 0) {
      uploadImage(fileList);
    }
  }, [fileList]);

  const props = {
    name: 'file',
    multiple,
    accept,
    maxCount,

    beforeUpload: (file, files) => {
      setFileList(files);

      return false;
    },
    showUploadList: false,
    ...remain
  };
  const handleDelete = (file) => {
    const newFileList = [...value].filter((item) => item.file_name !== file.file_name);
    onChange(newFileList);
  };

  const previewFiles = value.map((file, index) => (
    <div className="file-item" key={index}>
      {file.file_view?.toLowerCase()?.endsWith('.pdf') ? (
        <>
          <Modal
            centered
            open={openPDFModals?.[index] || false}
            width={false}
            bodyStyle={{ height: '100vh', overflow: 'scroll' }}
            footer={null}
            onCancel={() => setPDFView(index, false)}
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <PDFViewer file_view={file.file_view} />
          </Modal>
          <PdfThumbnail file={file.file_view} onClick={() => setPDFView(index, true)} />
          {/* <Image
            width={96}
            height={96}
            style={{ objectFit: 'cover', borderRadius: '5px' }}
            src="/pdf-icon.png"
            preview={false}
            onClick={() => setPDFView(index, true)}
          /> */}
        </>
      ) : file.file_view?.toLowerCase().endsWith('xlsx') ? (
        <Image
          loading="lazy"
          width={96}
          height={96}
          style={{ objectFit: 'cover', borderRadius: '5px' }}
          src={process.env.PUBLIC_URL + '/xlsx-icon.png'}
          preview={false}
        />
      ) : (
        <Image
          loading="lazy"
          width={96}
          height={96}
          style={{ objectFit: 'cover', borderRadius: '5px' }}
          src={file?.file_view || process.env.PUBLIC_URL + '/1px.png'}
        />
      )}

      {!isViewOnly && (
        <Button className="mt-1" onClick={() => handleDelete(file)}>
          이미지 삭제
        </Button>
      )}
    </div>
  ));

  return (
    <>
      {isViewOnly ? (
        <div className="list-file" style={{ border: 'none' }}>
          {previewFiles}
        </div>
      ) : (
        <>
          {value.length === 0 ? (
            <Upload {...props} className="upload-custom">
              <Card bodyStyle={{ width: '100%', padding: '10px 0 0 0' }}>
                <div className="d-flex align-items-center flex-column">
                  <UploadIcon />
                  <p className="ant-upload-text" style={{ color: '#6941C6' }}>
                    {description}
                  </p>
                  <p className="ant-upload-hint">{upload_hint}</p>
                </div>
              </Card>
            </Upload>
          ) : (
            <Card bodyStyle={{ width: '100%' }} className="container-upload">
              <Upload {...props} className="upload-custom">
                <Button block disabled={value.length >= maxCount} loading={uploading}>
                  {description}
                </Button>
              </Upload>
              <div className="list-file"> {previewFiles}</div>
            </Card>
          )}
        </>
      )}
      <Modal
        className="progress-upload"
        centered
        open={uploading}
        footer={null}
        maskClosable={false}
        title={null}
        closable={false}>
        <Progress
          percent={percentUpload}
          size={'small'}
          status="active"
          strokeColor={{ from: '#10264D', to: '#10264D' }}
        />
      </Modal>
    </>
  );
};

export default UploadFileCustom;
