import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { regX_password, getOptionsValidPassword } from '../../../utility/utils';
import apiClient from '../../../services/axios';
import LoginNowModal from '../../features/common/components/LoginNowModal';
import { LogoIcon } from '../../../assets/icons/Icons';

const { Text } = Typography;

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, _] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState(null);
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      message.error('잘못된 요청');
      navigate('/login');
    }
    apiClient
      .post('user/forgot-password/verify', { token })
      .then(({ data }) => setEmail(data.email))
      .catch(() => {
        message.error('잘못된 요청');
        navigate('/login');
      });
  }, [token]);

  const isFormValid = (values) => {
    const isValidPassword = values.password && regX_password.test(values.password);
    const isPasswordMatched = values.password === values.confirm_password;
    return isValidPassword && isPasswordMatched;
  };

  const submit = async (values) => {
    try {
      setLoading(true);
      await apiClient.post('user/forgot-password/confirm', { token, ...values });
      setOpenModal(true);
    } catch (e) {
      message.error('비밀번호 재설정 실패');
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginPage">
      <div className="content">
        <div className="logo">
          <LogoIcon width={201} height={52} />
        </div>
        <Form form={form} name="reset-password" layout="vertical" colon={false} onFinish={submit}>
          <Text
            style={{ display: 'block', textAlign: 'center', fontSize: '20px', fontWeight: 500, marginBottom: '30px' }}>
            비밀번호 재설정
          </Text>
          <Text
            style={{ display: 'block', textAlign: 'center', fontSize: '16px', fontWeight: 400, marginBottom: '30px' }}>
            E-mail : {email}
          </Text>
          {/* Password */}
          <Form.Item
            name="password"
            label={<div className="text-14 gray-900 fw-5">비밀번호</div>}
            style={{ marginBottom: '8px' }}>
            <Input.Password placeholder="비밀번호 입력" size="large" />
          </Form.Item>
          <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
            {({ getFieldValue }) => {
              const config = getOptionsValidPassword(getFieldValue('password'));
              const item = config.map((item) => (
                <div key={item.item} className="d-flex ms-1" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                  <CheckOutlined /> <div className="ms-1">{item.description}</div>
                </div>
              ));
              return <div className="d-flex">{item}</div>;
            }}
          </Form.Item>
          {/* Confirm Password */}
          <Form.Item name="confirm_password" style={{ marginBottom: '8px' }}>
            <Input.Password placeholder="비밀번호 확인" size="large" />
          </Form.Item>
          <Form.Item shouldUpdate={(preValue, curValue) => preValue.confirm_password !== curValue.confirm_password}>
            {({ getFieldValue }) => {
              const item = {
                key: 4,
                description: '비밀번호 일치',
                isValid:
                  getFieldValue('confirm_password') && getFieldValue('password') == getFieldValue('confirm_password')
              };
              return (
                <div key={item.item} className="d-flex" style={{ color: item.isValid ? '#1570EF' : '#F63D68' }}>
                  {item.isValid ? <CheckOutlined /> : <CloseOutlined />} <div className="ms-1">{item.description}</div>
                </div>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldsValue }) => (
              <Button
                htmlType="submit"
                block
                type="primary"
                loading={loading}
                size="large"
                disabled={!isFormValid(getFieldsValue())}>
                로그인
              </Button>
            )}
          </Form.Item>
        </Form>
        <LoginNowModal
          title={<div className="text-16 gray-900 fw-7">비밀번호 재설정 성공</div>}
          username={email}
          password={form.getFieldValue('password')}
          open={openModal}
          onLoggedIn={() => {
            setOpenModal(false);
            navigate(`/refund-request`);
          }}
        />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
