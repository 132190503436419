import { DoubleLeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchUserInfoThunk, logoutThunk } from '../../redux/reducers/authSlice';
import {
  closeSideBar,
  openContactAdmin,
} from '../../redux/reducers/layoutSlice';
import ContactAdmin from './ContactAdmin';
import SendInquiry from './SendInquiry';
import BusinessIntroduce from './BusinessIntroduce';
import { USER_ROLE } from '../utilities/constants';
import { ReactComponent as IconHomeOutlined } from './IconHomeOutlined.svg';
import './layout-style.scss';
import BottomNavigation from '../components/BottomNavigation';
import { LogoIcon } from '../../assets/icons/Icons';

const { Text } = Typography;

const HTVLayout = () => {
  const [selectedKey, setSelectedKey] = useState('refund-request');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [isShowBottomNav, setIsShowBottomNav] = useState(true);
  const { isOpenSideBar } = useSelector((state) => state.layout);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location?.pathname && location?.pathname !== selectedKey) {
      setSelectedKey(location?.pathname?.split('/')?.[1]);
    }
    dispatch(fetchUserInfoThunk());
  }, [dispatch]);


  useEffect(() => {
    if (['/onboarding', '/onboarding/'].includes(location?.pathname)) {
      setIsShowBottomNav(false);
    } else if (['/create-refund-request', '/refund-request/edit'].includes(location?.pathname) && location?.search?.includes('step=2')) {
      setIsShowBottomNav(false);
    } else {
      setIsShowBottomNav(true);
    }
  }, [location]);

  const getLabelItem = (label, count) => {
    return (
      <>
        <p>{label}</p>{' '}
        <div className="d-flex align-items-center">{count && <b style={{ color: 'red' }}>{count}</b>}</div>
      </>
    );
  };

  const navItems = [
    {
      key: 'sub1',
      label: '환급',
      type: 'group',
      children: [
        {
          label: getLabelItem(<><span>📑</span>신청 하기</>, undefined),
          key: 'refund-request'
        },
        {
          label: getLabelItem(<><span>💶</span>환급금</>, undefined),
          key: 'refund'
        },
        {
          label: getLabelItem(<><span>📍</span>환급 및 배송 주소</>, undefined),
          key: 'refund-destination',
          danger: true
        }
      ].concat(
        userInfo?.role === USER_ROLE.FreeTCustomer
          ? [
              {
                label: getLabelItem(<><span>🚚</span>해외운임료</>, undefined),
                key: 'shipping-cost'
              }
            ]
          : []
      )
    },
    {
      key: 'sub2',
      label: '고객센터',
      type: 'group',
      children: [
        {
          label: getLabelItem(<><span>❓</span>FAQ</>, undefined),
          key: 'faq',
          onClick: () => window.open('https://www.notion.so/hautevie/FAQ-d795297ce98142d2a25ee870b1d41d48')
        },
        {
          label: getLabelItem(<><span>📢</span>공지사항</>, undefined),
          key: 'link',
          onClick: () => window.open('https://hautevie.notion.site/NOTICE-9e8527f5374c4fd7b1e89709f099a2a5')
        },
        {
          label: getLabelItem(<><span>🔠</span>이용 방법 확인하기</>, undefined),
          key: 'onboarding',
          // key: 'link-onboarding',
          // onClick: () => window.open('https://hautevie.notion.site/FAQ-d795297ce98142d2a25ee870b1d41d48?pvs=4')
        },
        {
          label: getLabelItem(<><span>✉️</span>문의 하기</>, undefined),
          key: 'send-email',
          onClick: () => {
            dispatch(closeSideBar());
            setTimeout(() => {
              dispatch(openContactAdmin());
            }, 500);
          }
        }
      ]
    }
  ];

  const handleOnlClickNav = (e) => {
    // User guide
    // if (e.key === 'user-guide') {
    //   // window.open('https://free-t.co.kr/#service', '_blank');
    //   dispatch(openBusinessIntroduce());
    // } else
    if (!['link', 'faq', 'send-email'].includes(e.key)) {
      const path = e.keyPath?.[0];
      setSelectedKey(e.key);
      navigate(path);
    }
    dispatch(closeSideBar());
  };

  const handleClickLogout = () => {
    dispatch(logoutThunk());
  };

  let cssCustom = {
    backgroundColor: '#fff',
    paddingBottom: '10px',
    // maxWidth: '420px',
    maxWidth: '720px',
    margin: '0 auto',
    minHeight: '100dvh',
    height: '100%',
    border: '1px solid #D0D5DD',
    borderBottom: '0px',
    borderTop: '0px'
  };

  const DrawerTitle = () => (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <LogoIcon />
        </div>
        <Button onClick={() => dispatch(closeSideBar())}>
          <DoubleLeftOutlined />
        </Button>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div>
          <div style={{ fontSize: '14px', color: '#101828' }}>
            <b>Hello, {userInfo?.full_name}</b>
          </div>
          <div style={{ fontSize: '12px', color: '#667085' }}>{userInfo?.username}</div>
        </div>
        <div>
          <Button
            style={{ height: '40px', lineHeight: '1' }}
            // icon={<UserOutline />}
            onClick={() => {
              navigate('/profile');
              dispatch(closeSideBar());
            }}>
            프로필 수정
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Layout style={cssCustom}>
        <Outlet />
        {isShowBottomNav && <BottomNavigation />}
      </Layout>
      <Drawer
        style={{
          backgroundColor: '#F9FAFB',
        }}
        className='htv-layout'
        title={<DrawerTitle />}
        placement="left"
        onClose={() => dispatch(closeSideBar())}
        open={isOpenSideBar}
        contentWrapperStyle={{
          width: '100%',
          // maxWidth: '420px'
          maxWidth: '720px'
        }}
        closable={false}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={selectedKey}
            style={{ height: '80%', borderRight: 0, backgroundColor: 'transparent' }}
            items={navItems}
            onClick={handleOnlClickNav}
          />
          <div>
            {/* <Button
              size="large"
              style={{
                marginBottom: '16px'
              }}
              onClick={() => {
                dispatch(closeSideBar());
                setTimeout(() => {
                  dispatch(openContactAdmin());
                }, 500);
              }}
              block
              className="d-flex">
              <div className="me-1">
                <Mail />
              </div>
              문의 하기
            </Button> */}
            <div className="d-flex justify-content-between">
              <Button
                // block
                type="text"
                size="large"
                icon={<IconHomeOutlined />}
                href="https://free-t.co.kr"
                className='home-btn'
                target="_blank"
                style={{
                  display: 'flex',
                  // marginTop: '16px',
                  padding: '0px',
                  textDecoration: 'none',
                  fontSize: '14px',
                  lineHeight: '40px'
                }}>
                서비스소개 바로가기
              </Button>
              <Text
                style={{
                  fontSize: '12px',
                  lineHeight: '3.5',
                  textDecoration: 'underline',
                  color: '#98A2B3'
                }}
                onClick={handleClickLogout}>
                Logout
              </Text>
            </div>
          </div>
        </div>
      </Drawer>
      <SendInquiry />
      <ContactAdmin />
      <BusinessIntroduce />
    </>
  );
};
export default HTVLayout;
