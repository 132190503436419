import { useQuery } from '@tanstack/react-query';
import { fetchRefundRequest } from '../../views/features/refundRequest/api';

export const useRefundRequests = (params) => {
  return useQuery({
    queryKey: ['refund-requests', params],
    queryFn: () => fetchRefundRequest(params),
    enabled: !!params,
    staleTime: 1000 * 20, // 20 seconds
    cacheTime: 1000 * 20, // 20 seconds
    refetchOnWindowFocus: true,
  });
};
