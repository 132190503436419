import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import _ from 'lodash';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown, CopyCardBlackIcon } from '../../../assets/icons/Icons';
import ActionIcon from '../../../common/components/ActionIcons';
import HeaderFT from '../../../common/layout/HeaderFT';
import {
  changePage,
  changeSize,
  openSearchDrawer,
  closeSearchDrawer
} from '../../../redux/reducers/refundRequestSlice';
import ProductCard from '../../features/refundRequest/components/ProductCard';
import SearchFilter from './SearchFilter';
import './style.scss';
import { COUNTRIES, INSPECTION_CUSTOMER_STATUS, USER_ROLE } from '../../../common/utilities/constants';
import { closeSideBar } from '../../../redux/reducers/layoutSlice';
import apiClient from '../../../services/axios';
import InspectionCustomerModal from '../../features/refundRequest/components/inspectionCustomerModal';
import { formatCurrency } from '../../../utility/utils';
import { inspectionCustomer } from '../../features/refundRequest/api';
import WaitForRejectionModal from '../../features/refundRequest/components/WaitForRejectionModal';
import { useRefundRequests } from '../../../hooks/refundRequest/useRefundRequests';
import { useQueryClient } from '@tanstack/react-query';
import { useRefundRequestStatuses } from '../../../hooks/refundRequest/useRefundRequestStatuses';
const { Text } = Typography;
const RefundRequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState('all');
  const [countrySelected, setCountrySelected] = useState(COUNTRIES.france.value);
  const [isUserFetch, setIsUserFetch] = useState(false);
  const [openRejectReasonModal, setOpenRejectReasonModal] = useState(false);
  const [openDeliveryPendingModal, setOpenDeliveryPendingModal] = useState(false);
  const [openNoticeModal, setOpenNoticeModal] = useState(false);
  const [deliveryPendingData, setDeliveryPendingData] = useState({});
  const [inspectionCustomerData, setInspectionCustomerData] = useState({});
  const [openInspectionCustomerModal, setOpenInspectionCustomerModal] = useState(false);
  const [openWaitForRejectionModal, setOpenWaitForRejectionModal] = useState(false);
  const [waitForRejectionData, setWaitForRejectionData] = useState({});
  const [openConfirmInspectionPaymentModal, setOpenConfirmInspectionPaymentModal] = useState(false);
  const [confirmInspectionPaymentData, setConfirmInspectionPaymentData] = useState({});
  const [inspectionTotalProducts, setInspectionTotalProducts] = useState(0);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const [searchParams] = useSearchParams();
  const [searchData, setSearchData] = useState({});
  const [sortBy, setSortBy] = useState('updated_at');  

  const { userInfo } = useSelector((state) => state.auth);
  const { page, size, isOpenSearchDrawer, filters } = useSelector(
    (state) => state.refundRequests
  );

  const { data: refundRequests, isLoading } = useRefundRequests({
    ...filters, status: selected, country: countrySelected, page, size
  });

  const { data: statuses, isLoading: isStatusesLoading } = useRefundRequestStatuses({
    ...filters, country: countrySelected
  });

  const key = searchParams.get('key') || 'all';
  const countryKey = searchParams.get('country') || COUNTRIES.france.value;
  const STATUS_OPTIONS = !isStatusesLoading ? [
    statuses?.find((s) => s.value === 'all'), // move 'all' to the top
    ...statuses?.filter((s) => s.value !== 'all')
  ].filter(Boolean) : [];

  useEffect(() => {
    setSelected(key);
    setCountrySelected(countryKey);
    setIsUserFetch(true);
    dispatch(closeSideBar());
  }, []);

  useEffect(() => {
    const noticeModalShowDate = localStorage.getItem('notice-modal-show-date');
    const isShowNoticeModal = !noticeModalShowDate || new Date(noticeModalShowDate) < new Date();
    if (isShowNoticeModal) {
      setOpenNoticeModal(true);
    }
  }, [localStorage]);

  useEffect(() => {
    const totalProduct =
      _.sumBy(confirmInspectionPaymentData?.invoices, (invoice) => {
        return _.sumBy(invoice.products, 'quantity') || 0;
      }) || 0;
    setInspectionTotalProducts(totalProduct);
  }, [confirmInspectionPaymentData]);

  useEffect(() => {
    if (!userInfo?.active && userInfo?.role === USER_ROLE.FreeTBusiness) {
      navigate('/inactive');
    }
    if (isUserFetch && userInfo && userInfo?.registered === false) {
      dispatch(closeSideBar());
      navigate('/onboarding');
    }
  }, [userInfo]);

  const handleChangeSelect = (value) => {
    setSelected(value);
    if (value) {
      navigate({
        search: createSearchParams({ key: value, country: countrySelected }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleCountriesSelect = (value) => {
    setCountrySelected(value);
    if (value) {
      navigate({
        search: createSearchParams({ key: selected, country: value }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleOnclickSubmitNewRefundRequest = () => {
    setOpenCreateRequestModal(true);
    // navigate('/create-refund-request');
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
  };

  const handleChangeSize = (current, size) => {
    dispatch(changeSize(size));
  };

  const handleOpenRejectModal = (id, reason) => {
    setOpenRejectReasonModal(true);
    setRejectId(id);
    setRejectReason(reason);
  };

  const handleCloseRejectModal = () => {
    setOpenRejectReasonModal(false);
    setRejectId(null);
    setRejectReason(null);
  };

  const handleDeliveryPendingModal = (data) => {
    setDeliveryPendingData(data);
    setOpenDeliveryPendingModal(true);
  };

  const handleInspectionCustomerModal = (data) => {
    setInspectionCustomerData(data);
    setOpenInspectionCustomerModal(true);
  };

  const handleWaitForRejectionModal = (data) => {
    setWaitForRejectionData(data);
    setOpenWaitForRejectionModal(true);
  };

  const handleCloseWaitForRejectionModal = () => {
    setWaitForRejectionData({});
    setOpenWaitForRejectionModal(false);
  };

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey: ['refund-requests'] });
    queryClient.invalidateQueries({ queryKey: ['refund-request-statuses'] });
  };

  const onCloseInspectionCustomerModal = () => {
    setInspectionCustomerData(null);
    setOpenInspectionCustomerModal(false);
  };

  const handleConfirmInspectionPayment = (data) => {
    setConfirmInspectionPaymentData(data);
    setOpenConfirmInspectionPaymentModal(true);
  };

  const handleDoNotShowAgainToday = () => {
    setOpenNoticeModal(false);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const timezoneOffset = tomorrow.getTimezoneOffset() * 60000;
    const localTomorrow = new Date(tomorrow.getTime() - timezoneOffset);
    localStorage.setItem('notice-modal-show-date', localTomorrow.toISOString());
  };

  const handleApproveInspection = async () => {
    try {
      const result = await inspectionCustomer(inspectionCustomerData.id, {
        status: INSPECTION_CUSTOMER_STATUS.APPROVED
      }); // Call API to approve inspection
      if (result) {
        message.success('출고 요청이 승인 되었습니다'); // Show success message
        refresh(); // Refresh data
      } else {
        message.error('Failed to approve inspection'); // Show error message
      }
    } catch (error) {
      message.error('Failed to approve inspection'); // Show error message
    }
    onCloseInspectionCustomerModal();
  };

  const handleRejectInspection = async () => {
    handleConfirmInspectionPayment(inspectionCustomerData);
  };

  const inspectionCustomerBtn = (
    <>
      <div className="w-100 mt-4 d-flex">
        <Button
          block
          type="primary"
          style={{ borderRadius: 5, height: 40, backgroundColor: '#F63D68' }}
          className="me-3"
          onClick={handleRejectInspection}>
          반품 요청
        </Button>
        <Button
          block
          type="primary"
          style={{ borderRadius: 5, height: 40, backgroundColor: '#3431D6' }}
          onClick={handleApproveInspection}>
          출고 요청
        </Button>
      </div>
    </>
  );

  return (
    <>
      <HeaderFT
        title={'부가세환급 신청'}
        rightIems={
          <ActionIcon
            style={{ padding: '0px', marginRight: '10px' }}
            icon={<Search size="24px" />}
            onClick={() => dispatch(openSearchDrawer())}
          />
        }
      />
      <Content style={{ paddingBottom: '60px' }}>
        <div className="container-page">
          <div
            onClick={() => {
              // navigate to refund-destination page
              navigate('/refund-destination');
            }}
            className="d-flex justify-content-between"
            style={{
              height: '84px',
              backgroundColor: '#3431D6',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              marginTop: '24px',
              padding: '20px 24px'
            }}>
            <div className="d-flex justify-content-between flex-column">
              <Text
                className="text-white"
                style={{
                  fontSize: '16px',
                  fontWeight: '700',
                  fontFamily: 'Gmarket Sans TTF',
                  fontStyle: 'normal',
                  lineHeight: '24px'
                }}>
                인보이스 발급 방법
              </Text>
              <Text
                className="text-white"
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'Gmarket Sans TTF',
                  fontStyle: 'normal',
                  lineHeight: '20px'
                }}>
                이용가이드 바로가기 {'->'}
              </Text>
            </div>
            <img
              src={process.env.PUBLIC_URL + '/book.png'}
              style={{ width: '92px', height: '64px', marginTop: '-10px' }}
            />
          </div>
          <Row gutter={10}>
            <Col span={8}>
              <Select
                value={countrySelected}
                onChange={handleCountriesSelect}
                style={{
                  width: '100%',
                  marginTop: '24px',
                  marginBottom: '22px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '5px'
                }}
                suffixIcon={<ChevronDown />}
                bordered={false}
                size="large"
                options={Object.values(COUNTRIES)}
              />
            </Col>
            <Col span={16}>
              <Select
                value={selected}
                onChange={handleChangeSelect}
                style={{
                  width: '100%',
                  marginTop: '24px',
                  marginBottom: '22px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '5px'
                }}
                suffixIcon={<ChevronDown />}
                bordered={false}
                size="large"
                options={STATUS_OPTIONS}
              />
            </Col>
          </Row>
          <Spin tip="Loading" spinning={isLoading}>
            {refundRequests?.resources?.length > 0 ? (
              <>
                <Row>
                  {refundRequests?.resources?.map((item, idx) => {
                    return (
                      <Col key={idx} xs={{ span: 24 }} lg={{ span: 24 }} className="mb-3">
                        <ProductCard
                          data={item}
                          key={idx}
                          handleOpenRejectModal={handleOpenRejectModal}
                          handleDeliveryPendingModal={handleDeliveryPendingModal}
                          handleInspectionCustomerModal={handleInspectionCustomerModal}
                          handleWaitForRejectionModal={handleWaitForRejectionModal}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <div className="d-flex justify-content-center mt-1">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={size}
                    total={refundRequests?.total}
                    onChange={handleChangePagination}
                    onShowSizeChange={handleChangeSize}
                  />
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center flex-column justify-content-center"
                style={{ marginTop: '10vh', alignItems: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/empty.png'} style={{ width: '183px' }} />
                <p style={{ textAlign: 'center', fontWeight: '700' }}>신청 내역이 없어요</p>
              </div>
            )}
          </Spin>
        </div>

        <SearchFilter
          open={isOpenSearchDrawer}
          onSaveSearchData={setSearchData}
          searchData={searchData}
          onClose={() => dispatch(closeSearchDrawer())}
        />
      </Content>
      <Modal
        closable={false}
        maskClosable={false}
        // open={openNoticeModal} # TODO: temporary off
        open={false}
        footer={null}
        centered
        title={
          <Text style={{ fontSize: 16 }} strong>
            [공지사항]
          </Text>
        }
        className="notice-modal">
        <div className="notice-modal-content">
          <Text>10월 14일부터 배송주소가 변경됩니다.</Text>
          <br />
          <Text>
            <Link to="/refund-destination" style={{ color: '#1890ff', fontWeight: 700 }}>
              [환급 및 배송 주소]
            </Link>
            에서 변경된 주소 확인 가능합니다.
          </Text>
          <br />
          <Text>이용에 혼란이 없도록 확인 부탁드립니다.</Text>
          <br />
          <br />
          <Text>감사합니다.</Text>
        </div>
        <Row>
          <Col span={12}>
            <Button
              style={{ width: '100%', height: 48, borderRadius: '0px 0px 0px 5px', borderRight: 0 }}
              type="default"
              onClick={handleDoNotShowAgainToday}>
              하루동안 보지 않기
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%', height: 48, borderRadius: '0px 0px 5px 0px' }}
              type="default"
              onClick={() => setOpenNoticeModal(false)}>
              닫기
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        closable={false}
        maskClosable={false}
        open={openConfirmInspectionPaymentModal}
        footer={false}
        centered
        title={'거절'}>
        <div className="w-100" style={{ fontSize: 14, fontWeight: 400 }}>
          <Text className="w-100">
            거절 시 검수 비용 4,500원 + 반품 수수료 7,500원,{' '}
            <strong style={{ color: '#FF0000' }}>제품당 12,000원의 수수료</strong>가 청구됩니다.
          </Text>
          <div className="mt-3 d-flex justify-content-between">
            <Text>총 수수료</Text>
            <Text style={{ fontWeight: '700', textAlign: 'right' }}>
              {inspectionTotalProducts}개*12,000원 = {formatCurrency(inspectionTotalProducts * 12000, 0)}원
            </Text>
          </div>
          <div className="mt-3">
            <Text className="w-100" style={{ fontWeight: 700 }}>
              아래 계좌로 검수 및 반품 수수료를 입금해 주세요.
            </Text>
            <div className="mt-2" style={{ borderRadius: 5, padding: 12, backgroundColor: '#F2F4F7' }}>
              <div className="d-flex justify-content-between">
                <Text>은행명</Text>
                <Text>국민은행</Text>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <Text>계좌번호</Text>
                <Space
                  onClick={() => {
                    message.success('Copy Card Number successfully.');
                    navigator.clipboard.writeText('80350100120333');
                  }}>
                  <div className="text-item">80350100120333</div>
                  <CopyCardBlackIcon />
                </Space>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <Text>예금주</Text>
                <Text>(주)오뜨비</Text>
              </div>
              <Divider className="my-2" />
              <div className="d-flex justify-content-between" style={{ fontWeight: '700', fontSize: '18px' }}>
                <Text>총 입금액</Text>
                <Text>{formatCurrency(inspectionTotalProducts * 12000, 0)}원</Text>
              </div>
            </div>
            <Text className="w-100 mt-2">* 입금 확인 후 해당 건은 현지 반품 처리됩니다.</Text>
          </div>
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => setOpenConfirmInspectionPaymentModal(false)} className="me-3" size="large">
            닫기
          </Button>
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: '#F63D68'
            }}
            onClick={async () => {
              try {
                const result = await apiClient.post(
                  `/refund-request/${confirmInspectionPaymentData?.id}/confirm-inspection-payment`
                );
                if (result?.status === 200) {
                  refresh();
                  message.success('반품 요청 되었습니다');
                } else {
                  message.success('Failed! Please try again!');
                }
              } catch (error) {
                message.success('Failed! Please try again!');
              } finally {
                setOpenConfirmInspectionPaymentModal(false);
                onCloseInspectionCustomerModal();
              }
            }}>
            {'입금 완료'}
          </Button>
        </div>
      </Modal>
      <Modal
        closable={false}
        maskClosable={false}
        open={openDeliveryPendingModal}
        footer={false}
        centered
        title={
          <div className="gray-900 fw-7 mb-3" style={{ fontSize: '16px' }}>
            {deliveryPendingData?.delivery_pending ? '출고 보류 해제' : '출고 보류'}
          </div>
        }>
        <div className="w-100">
          {deliveryPendingData?.delivery_pending ? (
            <>
              <Text className="w-100">
                출고 보류를 해제 하려면 아래 <strong style={{ color: '#F63D68' }}>{'"출고 보류 해제"'}</strong> 버튼을
                선택해 주세요
              </Text>
            </>
          ) : (
            <>
              <Text className="mb-4 w-100">
                해당 신청서를 출고 보류 하려면 아래 <strong style={{ color: '#F63D68' }}>{'"출고 보류"'}</strong> 버튼을
                선택해 주세요
              </Text>
              <br />
              <br />
              <Text className="w-100 gray-900" style={{ color: '#F63D68' }}>
                *출고 보류는 원할 때 언제든지 해제할 수 있어요
              </Text>
            </>
          )}
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => setOpenDeliveryPendingModal(false)} className="me-3" size="large">
            닫기
          </Button>
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: deliveryPendingData?.delivery_pending ? '#FECDD6' : '#F63D68',
              color: deliveryPendingData?.delivery_pending ? '#000' : '#FFF'
            }}
            onClick={async () => {
              try {
                const result = await apiClient.post(`/refund-request/${deliveryPendingData?.id}/delivery-pending`);
                if (result?.status === 201) {
                  refresh();
                  message.success('Success!');
                } else {
                  message.success('Failed! Please try again!');
                }
              } catch (error) {
                message.success('Failed! Please try again!');
              } finally {
                setOpenDeliveryPendingModal(false);
              }
            }}>
            {deliveryPendingData?.delivery_pending ? '출고 보류 해제' : '출고 보류'}
          </Button>
        </div>
      </Modal>
      <Drawer
        title={'검수 사진'}
        placement={'bottom'}
        onClose={() => setOpenInspectionCustomerModal(false)}
        open={openInspectionCustomerModal}
        rootClassName="optiosDrawer"
        height="auto"
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px'
        }}
        style={{
          borderRadius: '12px 12px 0px 0px'
        }}>
        <InspectionCustomerModal data={inspectionCustomerData} inspectionCustomerBtn={inspectionCustomerBtn} />
      </Drawer>
      <Modal
        closable={false}
        maskClosable={false}
        open={openWaitForRejectionModal}
        footer={false}
        centered>
        <WaitForRejectionModal
          data={waitForRejectionData}
          handleCloseWaitForRejectionModal={handleCloseWaitForRejectionModal}
          refresh={refresh}
        />
      </Modal>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => handleCloseRejectModal()}
        open={openRejectReasonModal}
        rootClassName="optiosDrawer"
        contentWrapperStyle={{
          height: '250px',
          // maxHeight: '420px',
          overflow: 'scroll'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            반려 사유
          </p>
          <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => handleCloseRejectModal()} />
        </div>
        <div>
          <Input.TextArea rows={3} value={rejectReason} readOnly />
          <Button
            type="primary"
            block
            size="large"
            className="mt-4"
            onClick={() => navigate(`/refund-request/edit?id=${rejectId}`)}>
            제품 수정
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default RefundRequestPage;
