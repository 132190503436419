import { EnglandCircle, FranceCircle, FranceFlag, KoreanFlag, SpainCircle } from '../../assets/icons/Icons';
import { filter } from 'lodash';

export const VAT_RATE = parseFloat(process.env.REACT_APP_VAT_RATE) || 0.2;
export const REFUND_RATE = parseFloat(process.env.REACT_APP_REFUND_RATE) || 0.1;

export const RefundRequestChoices = {
  DRAFT: 'draft',
  PENDING: 'pending',
  REQUEST_TAX_REFUND: 'request_tax_refund',
  LOCAL_DEPARTURE: 'localdeparture',
  READY_FOR_INVOICING: 'ready_for_invoicing',
  WAREHOUSE_IN: 'warehouse_in',
  DELIVERED: 'delivered',
  POE_ARRIVAL: 'poe_arrival',
  CUSTOMS_CLEARED: 'customs_cleared',
  REQUEST_LOCAL: 'request_local',
  CONFIRMING_PAYMENT: 'confirming_payment',
  RETURN_COMPLETED: 'return_completed',
  REJECTED: 'rejected',
  TAX_REFUND_PROCESS: 'tax_refund_process',
  REFUNDED: 'refunded',
  WAIT_FOR_REJECTION: 'wait_for_rejection',
  REQUEST_SHIPPING: 'request_shipping'
}

export const REFUND_REQUEST_STATUS = [
  {
    value: RefundRequestChoices.DRAFT,
    label: '임시저장',
    dot: 0,
    dotColor: '#4BBF9FF2',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: RefundRequestChoices.PENDING,
    label: '대기중',
    dot: 1,
    dotColor: '#4BBF9FF2',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: RefundRequestChoices.REQUEST_TAX_REFUND,
    label: '신청서 작성 완료',
    dot: 1,
    dotColor: '#4BBF9FF2',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: RefundRequestChoices.LOCAL_DEPARTURE,
    label: '배송대기',
    dot: 2,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#EAAA08'
  },
  {
    value: RefundRequestChoices.READY_FOR_INVOICING,
    label: '반출 완료',
    dot: 2,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#EAAA08'
  },
  {
    value: RefundRequestChoices.WAREHOUSE_IN,
    label: '물류센터 입고',
    dot: 2,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#EAAA08'
  },
  {
    value: RefundRequestChoices.DELIVERED,
    label: '배송 완료',
    dot: 3,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9F'
  },
  {
    value: RefundRequestChoices.POE_ARRIVAL,
    label: '인천입항',
    dot: 3,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9F'
  },
  {
    value: RefundRequestChoices.CUSTOMS_CLEARED,
    label: '세관통관 완료',
    dot: 3,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9F'
  },
  {
    value: RefundRequestChoices.REQUEST_LOCAL,
    label: '택스 리펀 현지 신청 완료',
    dot: 4,
    dotColor: '#4BBF9FF2',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: RefundRequestChoices.TAX_REFUND_PROCESS,
    label: '환급 진행중',
    dot: 5,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#EAAA08'
  },
  {
    value: RefundRequestChoices.REFUNDED,
    label: '환급 완료',
    dot: 6,
    dotColor: '#52C0A2',
    color: '#FFFFFF',
    backgroundColor: '#98A2B3'
  },
  {
    value: RefundRequestChoices.REJECTED,
    label: '반려',
    dot: 6,
    dotColor: '#F63D68',
    color: '#FFFFFF',
    backgroundColor: '#F63D68'
  },
  {
    value: RefundRequestChoices.WAIT_FOR_REJECTION,
    label: '거절대기',
    dot: 3,
    dotColor: '#F63D68',
    color: '#FFFFFF',
    backgroundColor: '#F63D68'
  },
  {
    value: RefundRequestChoices.CONFIRMING_PAYMENT,
    label: '입금 확인중',
    dot: 3,
    dotColor: '#F63D68',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: RefundRequestChoices.RETURN_COMPLETED,
    label: '반품 완료',
    dot: 6,
    dotColor: '#F63D68',
    color: '#FFFFFF',
    backgroundColor: '#F63D68'
  },
  {
    value: RefundRequestChoices.REQUEST_SHIPPING,
    label: '출고 요청',
    dot: 1,
    dotColor: '#4BBF9F',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9F'
  }
];

export const MOBILE_TYPE_OPTIONS = [
  {
    label: 'Korea +82',
    value: 'south_korea',
    icon: <KoreanFlag />,
    code: '+82'
  },
  {
    label: 'France +33',
    value: 'france',
    icon: <FranceFlag />,
    code: '+33'
  }
];

export const NUMBER_KOREAN = ['첫', '두', '세', '네', '다섯', '여섯', '일곱', '여덟', '아홉', '열'];

export const getStatusLabel = (key, constantList) => {
  return filter(constantList, ['value', key])[0]?.label || '전체';
};

export const CURRENCIES = {
  KRW: { name: 'KRW', symbol: '₩', label: '원', scale: 0, text: '원' },
  EUR: { name: 'EUR', symbol: '€', label: '유로', scale: 2, text: 'EU' },
  GBP: { name: 'GBP', symbol: '£', label: '파운드', scale: 2, text: '영국' }
};

export const RECIPIENT_COUNTRIES = {
  KOREA: { value: 'KR', label: '한국', icon: <img src={process.env.PUBLIC_URL + '/icons/kr.png'} alt='kr' /> },
  NEW_ZEALAND: { value: 'NZ', label: '뉴질랜드', icon: <img src={process.env.PUBLIC_URL + '/icons/nz.png'} alt='nz' /> },
  TAIWAN: { value: 'TW', label: '대만', icon: <img src={process.env.PUBLIC_URL + '/icons/tw.png'} alt='tw' /> },
  RUSSIA: { value: 'RU', label: '러시아', icon: <img src={process.env.PUBLIC_URL + '/icons/ru.png'} alt='ru' /> },
  MALAYSIA: { value: 'MY', label: '말레이시아', icon: <img src={process.env.PUBLIC_URL + '/icons/my.png'} alt='my' /> },
  MEXICO: { value: 'MX', label: '멕시코', icon: <img src={process.env.PUBLIC_URL + '/icons/mx.png'} alt='mx' /> },
  USA: { value: 'US', label: '미국', icon: <img src={process.env.PUBLIC_URL + '/icons/us.png'} alt='us' /> },
  VIETNAM: { value: 'VN', label: '베트남', icon: <img src={process.env.PUBLIC_URL + '/icons/vn.png'} alt='vn' /> },
  BRAZIL: { value: 'BR', label: '브라질', icon: <img src={process.env.PUBLIC_URL + '/icons/br.png'} alt='br' /> },
  SWITZERLAND: { value: 'CH', label: '스위스', icon: <img src={process.env.PUBLIC_URL + '/icons/ch.png'} alt='ch' /> },
  SINGAPORE: { value: 'SG', label: '싱가포르', icon: <img src={process.env.PUBLIC_URL + '/icons/sg.png'} alt='sg' /> },
  UK: { value: 'GB', label: '영국', icon: <img src={process.env.PUBLIC_URL + '/icons/uk.png'} alt='uk' /> },
  INDIA: { value: 'IN', label: '인도', icon: <img src={process.env.PUBLIC_URL + '/icons/in.png'} alt='in' /> },
  INDONESIA: { value: 'ID', label: '인도네시아', icon: <img src={process.env.PUBLIC_URL + '/icons/id.png'} alt='id' /> },
  JAPAN: { value: 'JP', label: '일본', icon: <img src={process.env.PUBLIC_URL + '/icons/jp.png'} alt='jp' /> },
  CHINA: { value: 'CN', label: '중국', icon: <img src={process.env.PUBLIC_URL + '/icons/cn.png'} alt='cn' /> },
  CANADA: { value: 'CA', label: '캐나다', icon: <img src={process.env.PUBLIC_URL + '/icons/ca.png'} alt='ca' /> },
  THAILAND: { value: 'TH', label: '태국', icon: <img src={process.env.PUBLIC_URL + '/icons/th.png'} alt='th' /> },
  AUSTRALIA: { value: 'AU', label: '호주', icon: <img src={process.env.PUBLIC_URL + '/icons/au.png'} alt='au' /> }
};

export const RECIPIENT_COUNTRIES_OPTIONS = [
  { value: RECIPIENT_COUNTRIES.KOREA.value, label: RECIPIENT_COUNTRIES.KOREA.label, icon: RECIPIENT_COUNTRIES.KOREA.icon },
  { value: RECIPIENT_COUNTRIES.NEW_ZEALAND.value, label: RECIPIENT_COUNTRIES.NEW_ZEALAND.label, icon: RECIPIENT_COUNTRIES.NEW_ZEALAND.icon },
  { value: RECIPIENT_COUNTRIES.TAIWAN.value, label: RECIPIENT_COUNTRIES.TAIWAN.label, icon: RECIPIENT_COUNTRIES.TAIWAN.icon },
  { value: RECIPIENT_COUNTRIES.RUSSIA.value, label: RECIPIENT_COUNTRIES.RUSSIA.label, icon: RECIPIENT_COUNTRIES.RUSSIA.icon },
  { value: RECIPIENT_COUNTRIES.MALAYSIA.value, label: RECIPIENT_COUNTRIES.MALAYSIA.label, icon: RECIPIENT_COUNTRIES.MALAYSIA.icon },
  { value: RECIPIENT_COUNTRIES.MEXICO.value, label: RECIPIENT_COUNTRIES.MEXICO.label, icon: RECIPIENT_COUNTRIES.MEXICO.icon },
  { value: RECIPIENT_COUNTRIES.USA.value, label: RECIPIENT_COUNTRIES.USA.label, icon: RECIPIENT_COUNTRIES.USA.icon },
  { value: RECIPIENT_COUNTRIES.VIETNAM.value, label: RECIPIENT_COUNTRIES.VIETNAM.label, icon: RECIPIENT_COUNTRIES.VIETNAM.icon },
  { value: RECIPIENT_COUNTRIES.BRAZIL.value, label: RECIPIENT_COUNTRIES.BRAZIL.label, icon: RECIPIENT_COUNTRIES.BRAZIL.icon },
  { value: RECIPIENT_COUNTRIES.SWITZERLAND.value, label: RECIPIENT_COUNTRIES.SWITZERLAND.label, icon: RECIPIENT_COUNTRIES.SWITZERLAND.icon },
  { value: RECIPIENT_COUNTRIES.SINGAPORE.value, label: RECIPIENT_COUNTRIES.SINGAPORE.label, icon: RECIPIENT_COUNTRIES.SINGAPORE.icon },
  { value: RECIPIENT_COUNTRIES.UK.value, label: RECIPIENT_COUNTRIES.UK.label, icon: RECIPIENT_COUNTRIES.UK.icon },
  { value: RECIPIENT_COUNTRIES.INDIA.value, label: RECIPIENT_COUNTRIES.INDIA.label, icon: RECIPIENT_COUNTRIES.INDIA.icon },
  { value: RECIPIENT_COUNTRIES.INDONESIA.value, label: RECIPIENT_COUNTRIES.INDONESIA.label, icon: RECIPIENT_COUNTRIES.INDONESIA.icon },
  { value: RECIPIENT_COUNTRIES.JAPAN.value, label: RECIPIENT_COUNTRIES.JAPAN.label, icon: RECIPIENT_COUNTRIES.JAPAN.icon },
  { value: RECIPIENT_COUNTRIES.CHINA.value, label: RECIPIENT_COUNTRIES.CHINA.label, icon: RECIPIENT_COUNTRIES.CHINA.icon },
  { value: RECIPIENT_COUNTRIES.CANADA.value, label: RECIPIENT_COUNTRIES.CANADA.label, icon: RECIPIENT_COUNTRIES.CANADA.icon },
  { value: RECIPIENT_COUNTRIES.THAILAND.value, label: RECIPIENT_COUNTRIES.THAILAND.label, icon: RECIPIENT_COUNTRIES.THAILAND.icon },
  { value: RECIPIENT_COUNTRIES.AUSTRALIA.value, label: RECIPIENT_COUNTRIES.AUSTRALIA.label, icon: RECIPIENT_COUNTRIES.AUSTRALIA.icon }
];

export const COUNTRIES = {
  france: {
    value: 'france',
    label: '프랑스',
    icon: <FranceCircle />,
    refund_rate: 0.1,
    vat_rate: 0.2,
    currency: CURRENCIES.EUR,
    refund_time: '1~2개월'
  },
  spain: {
    value: 'spain',
    label: '스페인',
    icon: <SpainCircle />,
    refund_rate: 0.1,
    vat_rate: 0.21,
    currency: CURRENCIES.EUR,
    refund_time: '2~4개월',
    disabled: process.env.REACT_APP_ENABLE_ALL_COUNTRIES === 'false'
  },
  uk: {
    value: 'uk',
    label: '영국',
    icon: <EnglandCircle />,
    refund_rate: 0.1,
    vat_rate: 0.2,
    currency: CURRENCIES.GBP,
    refund_time: '2~4개월',
    disabled: process.env.REACT_APP_ENABLE_ALL_COUNTRIES === 'false'
  }
};

export const COUNTRIES_OPTIONS = [
  { value: COUNTRIES.france.value, label: COUNTRIES.france.label, icon: COUNTRIES.france.icon },
  { value: COUNTRIES.spain.value, label: COUNTRIES.spain.label, icon: COUNTRIES.spain.icon },
  { value: COUNTRIES.uk.value, label: COUNTRIES.uk.label, icon: COUNTRIES.uk.icon }
];

export const TRANSFER_TYPE = {
  REFUND: 'refund'
};

export const USER_ROLE = {
  FreeTBusiness: 'free_t',
  FreeTCustomer: 'free_t_customer'
};

export const DEFAULT_QUANTITY_DROPDOWN = [
  ...Array.from({ length: 20 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }))
];

export const DEFAULT_HS_CODE_DROPDOWN = [
  { value: '950300', label: '장난감류' },
  { value: '711719', label: '쥬얼리' },
  { value: '611190', label: '유아의류' },
  { value: '910119', label: '시계' },
  { value: '620299', label: '의류' },
  { value: '640590', label: '신발류' },
  { value: '420329', label: '패션잡화' },
  { value: '330300', label: '뷰티' },
  { value: '392410', label: '주방/생활' },
  { value: '940330', label: '가구/인테리어' },
  { value: '851660', label: '가전/공구/디지털' },
  { value: '490199', label: '서적/인쇄물류' },
  { value: '950639', label: '스포츠/레저용품' }
];

export const INSPECTION_CUSTOMER_STATUS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const REJECTION_STATUS = {
  SHIPPED_WITHOUT_TAX_REFUND: 'shipped_without_tax_refund',
  DEPOSIT_COMPLETED: 'deposit_completed',
  APPROVED: 'approved'
};
