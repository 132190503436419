import { Button, Col, Drawer, Row, Select, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import * as _ from 'lodash';
import HeaderFT from '../../../common/layout/HeaderFT';
import { formatCurrency } from '../../../utility/utils';
import { COUNTRIES } from '../../../common/utilities/constants';
import { ChevronDown } from 'react-feather';
import { fetchCurrency } from '../../../common/api';
const { Title, Text } = Typography;
const ShippingCostPage = () => {
  const [openNoticeModal, setOpenNoticeModal] = React.useState(false);
  const [country, setCountry] = React.useState(COUNTRIES.france);
  const [currencies, setCurrencies] = React.useState([]);
  const [countriesOptions, setCountriesOptions] = React.useState([]);
  const data = {};
  React.useEffect(() => {
    fetchCurrency().then((res) => {
      setCurrencies(res.resources);
    });
  }, []);
  React.useEffect(() => {
    const countries_options = Object.values(COUNTRIES).map((country) => {
      const currency = _.find(currencies, { name: country?.currency?.name });
      const krwCurrency = _.find(currencies, { name: 'KRW' });
      const conversions = currency?.conversions;
      const conversionRate = _.find(conversions, { conversion_currency_id: krwCurrency?.id })?.conversion_rate || 1;
      return {
        label: (
          <>
            <Space>
              <div style={{ lineHeight: '0' }}>{country.icon}</div>
              <Text>{country.label}</Text>
              <Text style={{ margin: '0px -5px', color: '#D0D5DD' }}>|</Text>
              <Text className="pt-1" style={{ marginLeft: '-5px' }}>
                {`부가 : 1`}{country?.value === COUNTRIES.uk.value ? '파운드' : '유로'}{`=${conversionRate}원`}
              </Text>
            </Space>
          </>
        ),
        value: country.value
      };
    });
    setCountriesOptions(countries_options);
  }, [currencies]);

  Object.values(COUNTRIES).forEach((country) => {
    const countryData = [];
    let stepCost = 0;
    let defaultCost = 11.8;
    let defaultCostText = '유로';
    switch (country.value) {
      case COUNTRIES.spain.value:
        defaultCost += 2;
        break;
      case COUNTRIES.uk.value:
        defaultCost -= 1;
        defaultCostText = '파운드';
        break;
    }
    for (let i = 0.5; i <= 20; i += 0.5) {
      countryData.push({
        weight: `${i}kg`,
        cost: `${formatCurrency(defaultCost + stepCost, 1)}${defaultCostText}`
      });
      stepCost += 2.3;
    }
    data[country.value] = countryData;
  });
  return (
    <>
      <HeaderFT
        title={'배송비'}
        rightIems={
          <Button size="large" style={{ width: '140px' }} onClick={() => setOpenNoticeModal(true)}>
            무게 측정 방법
          </Button>
        }
      />
      <Content className="p-3">
        <Select
          value={country?.value}
          onChange={(value) => {
            setCountry(COUNTRIES?.[value] || COUNTRIES.france);
          }}
          style={{
            width: '100%',
            marginBottom: '10px',
            border: '1px solid #D0D5DD',
            borderRadius: '5px'
          }}
          suffixIcon={<ChevronDown />}
          bordered={false}
          size="large"
          options={countriesOptions}
          optionLabelProp="label" // Ensure the correct label is displayed after selection
        />
        <Title
          level={5}
          style={{
            fontSize: '16px',
            fontWeight: '500'
          }}>
          배송비는 <strong style={{ color: 'red' }}>예상 환급액에서 자동 차감</strong>되고, 배송비가 환급액을 넘어가는
          경우에만 결제 안내됩니다.
        </Title>
        <div
          style={{
            textAlign: 'center',
            border: '1px solid #dfdfdf',
            borderRadius: '5px 5px 5px 5px',
            marginTop: '15px',
            marginBottom: '50px'
          }}>
          <Row
            className="p-2"
            style={{
              backgroundColor: '#10264D',
              borderRadius: '5px 5px 0 0',
              color: '#fff',
              fontWeight: '700'
            }}>
            <Col span={12}>무게</Col>
            <Col span={12}>운임료</Col>
          </Row>
          {data[country.value]?.map((i, idx) => (
            <Row
              className="p-2"
              key={`sc${idx}`}
              style={{ fontWeight: '500', backgroundColor: idx % 2 ? '#F2F4F7' : '#fff' }}>
              <Col span={12}>{i.weight}</Col>
              <Col span={12}>{i.cost}</Col>
            </Row>
          ))}
        </div>
      </Content>
      <Drawer
        title={'무게 측정 방법'}
        placement={'bottom'}
        onClose={() => setOpenNoticeModal(false)}
        open={openNoticeModal}
        rootClassName="optiosDrawer"
        height="auto"
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px'
        }}
        style={{
          borderRadius: '12px 12px 0px 0px'
        }}>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: '500'
          }}>
          <strong style={{ fontWeight: '700' }}>{'항공사에 따른 부피 무게 측정 방법:'}</strong> <br />{' '}
          {'가로 x 세로 x 높이 cm / 6000 실질 무게와 부피 무게 중 큰 무게로 측정됩니다.'}
        </Text>
      </Drawer>
    </>
  );
};

export default ShippingCostPage;
